import React, {
    createContext,
    useState,
    useCallback,
    useEffect,
    useContext,
} from "react";
import {useQuery, useMutation} from "@apollo/client";
import {SEE_SCAR_TOTAL_COUNT, SEE_CHECK_LIST_STATISTICS} from "graphql/Main/ProjectDetail/Stats/query";
import {SCAR_STATISTICS_EXCEL, CHECK_LIST_STATISTICS_EXCEL} from "graphql/Main/ProjectDetail/Stats/mutation";
import StatsPresenter from "./StatsPresenter";
import {toast} from "react-toastify";
import {AppContext} from "App";
import { SAFEY_ACTION_DOCUMENT_DOWN_ALL } from "graphql/Main/Project/mutation";

export const StatsContext = createContext();

const StatsContainer = () => {
    const {language} = useContext(AppContext);
    const id = Number(localStorage.getItem("prjId"));
    const [statsType, setStatsType] = useState("scar");
    const [byScarCount, setByScarCount] = useState(null);
    const [byLabelScarCount, setByLabelScarCount] = useState(null);
    const [byStatus, setByStatus] = useState(null);
    const [labelList, setLabelList] = useState([]);
    const now = new Date();
    const searchStartDate = new Date(2019, 0, 1);
    const searchEndDate = new Date(now.getFullYear() + 1, 11, 31);
    const [inputs, setInputs] = useState({
        startDate: searchStartDate,
        endDate: searchEndDate,
    });

    const [scarStatisticsExcel] = useMutation(SCAR_STATISTICS_EXCEL);
    const [checkListStatisticsExcel] = useMutation(CHECK_LIST_STATISTICS_EXCEL);
    const [safeyActionDocumentDownAll] = useMutation(SAFEY_ACTION_DOCUMENT_DOWN_ALL);

    const {data: statsData, loading: statsLoading, refetch: statsRefetch} = useQuery(SEE_SCAR_TOTAL_COUNT, {
        variables: {
            prj_id: id,
            searchStart: inputs.startDate,
            searchEnd: inputs.endDate,
        },
        fetchPolicy: 'network-only'
    });
    const {data: checkListStatsData, loading: checkListLoading} = useQuery(SEE_CHECK_LIST_STATISTICS, {
        variables: {
            prj_id: id,
            searchStart: inputs.startDate,
            searchEnd: inputs.endDate,
        },
        fetchPolicy: 'network-only'
    });
    React.useEffect(() => console.info('checkListStatsData', checkListStatsData), [checkListStatsData])

    const handleReset = useCallback(() => {
        setInputs({
            startDate: searchStartDate,
            endDate: searchEndDate,
        });
    }, []);    

    const handleExcel = useCallback(async () => {
        try {
            switch (statsType) {
                case 'scar':
                    const {data} = await scarStatisticsExcel({
                        variables: {
                            prj_id: id,
                            searchStart: inputs.startDate,
                            searchEnd: inputs.endDate,
                        },
                    });
                    if (data?.scarStatisticsExcel?.result) {
                        window.open(data.scarStatisticsExcel.excel);
                        toast.success((language === 'KOR' && '액셀 파일을 다운받았습니다.') || (language === 'ENG' && 'Downloaded successfully'));
                    }
                    if (data?.scarStatisticsExcel?.error !== "") {
                        toast.error(data?.scarStatisticsExcel?.error);
                    }
                    break;
                case 'checkList':
                    const {data: checkListData} = await checkListStatisticsExcel({
                        variables: {
                            prj_id: id,
                            searchStart: inputs.startDate,
                            searchEnd: inputs.endDate,
                        },
                    });

                    console.info(checkListData);

                    if (checkListData?.checkListStatisticsExcel?.result) {
                        toast.success((language === 'KOR' && '액셀 파일을 다운받았습니다.') || (language === 'ENG' && 'Downloaded successfully'));
                        window.open(checkListData.checkListStatisticsExcel.excel);
                    }
                    if (checkListData?.checkListStatisticsExcel?.error !== "") {
                        toast.error(checkListData?.checkListStatisticsExcel?.error);
                    }
                    break;
            }

        } catch (e) {
            toast.error(e.message);
        }
    }, [inputs, statsType]);

    const handleScarDocDown = useCallback(async () => {
        
        if (!id) return;
        try {
          const { data } = await safeyActionDocumentDownAll({
            variables: {
              prjId: id,
              startDate: toStringByFormatting(inputs.startDate),
              endDate: toStringByFormatting(inputs.endDate)
            },
          });
          if (data?.safeyActionDocumentDownAll?.result) {
            window.open(data?.safeyActionDocumentDownAll?.zipUrl);
            toast.success(
              (language === "KOR" && "안전조치시정서를 다운로드하였습니다.") ||
                (language === "ENG" && "Download")
            );
          } else {
            toast.error(
              (language === "KOR" && "안전조치시정서 다운로드에 실패하였습니다.") ||
                (language === "ENG" && "Download Fail")
            );
          }
        } catch (e) {
          toast.error(e.message);
        }
      }, [inputs]);
    
    function toStringByFormatting(source, delimiter = '.') {
        const year = source.getFullYear();
        const month = source.getMonth() + 1 < 10 ? '0'+ (source.getMonth() + 1) : source.getMonth() + 1;
        const day = source.getDate() < 10 ? '0'+ source.getDate() : source.getDate();
    
        return [year, month, day].join(delimiter);
    }


    useEffect(() => {
        if (statsData?.seeScarTotalCount?.result) {
            setByScarCount(statsData.seeScarTotalCount.byScarCount);
            if(statsData.seeScarTotalCount.byLabelScarCount.length > 0 ){
                const sortList = statsData.seeScarTotalCount.byLabelScarCount.sort((a, b) => (Number(a.createCount) >= Number(b.createCount) ? -1 : 1));
                setByLabelScarCount(sortList);
            }
            
            setByStatus(statsData.seeScarTotalCount.byStatus);
            setLabelList(statsData.seeScarTotalCount.labelList);
        }
    }, [statsData]);

    return (
        <StatsContext.Provider
            value={{
                inputs,
                setInputs,
                handleReset,
                handleExcel,
                byScarCount,
                byLabelScarCount,
                byStatus,
                labelList,
                statsType,
                setStatsType,
                checkListStatsData: checkListStatsData?.seeCheckListStatistics,
                handleScarDocDown
            }}
        >
            <StatsPresenter
                language={language}
                checkListStatsData={checkListStatsData?.seeCheckListStatistics}
                prjId={id}
            />
        </StatsContext.Provider>
    );
};

export default StatsContainer;
