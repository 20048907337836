import { gql } from "@apollo/client";

export const SAFEY_ACTION_DOCUMENT_DOWN_ALL = gql`
  mutation safeyActionDocumentDownAll($prjId: Int, $startDate: String, $endDate: String) {
    safeyActionDocumentDownAll(prj_id: $prjId , start_date:$startDate , end_date: $endDate) {
      result
      zipUrl
    }
  }
`;
